
import React from "react";
export default function NoPage() {
    return (
        <div className="hero min-h-screen bg-base-100">
            <div className="hero-content text-center">
                <div className="max-w-md">
                    <h1 className="text-5xl font-bold">404! <br></br> Page Not Found</h1>
                </div>
            </div>
        </div>

    );
}