import React from "react";



export default function Portfolio() {
    return (

        <div className="min-h-screen bg-base-100 relative" data-theme={"luxury"}>

            <div className="font-bold text-4xl text-center mt-12 pt-12">Languages</div>



            <div className="overflow-x-auto mt-12 ml-6 mr-6 shadow-lg p-8 bg-primary-content bg-opacity-20 rounded-lg mt-12">
                <table className="table">
                    {/* head */}
                    <thead>
                    <tr>
                        <th>Language</th>
                        <th>&#127919; Skill Level</th>
                        <th>&#11088; Highlighted Project </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* row 1 */}
                    <tr>

                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://seeklogo.com/images/C/c-sharp-c-logo-02F17714BA-seeklogo.com.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-mono text-xl">C#</div>
                                    <div className="text-sm opacity-50">Microsoft</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Advanced
                            <br/>
                            <span className="badge badge-ghost badge-sm">10yrs</span>
                        </td>
                        <td><a href="https://westonforbes.com/projects/fileconvert">FileConvert</a></td>

                    </tr>
                    {/* row 2 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://www.pngall.com/wp-content/uploads/2016/05/Java-Free-Download-PNG.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-mono text-xl">Java</div>
                                    <div className="text-sm opacity-50">Oracle</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Intermediate
                            <br/>
                            <span className="badge badge-ghost badge-sm">5yrs</span>
                        </td>
                        <td>N/A</td>
                    </tr>
                    {/* row 3 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://cdn.iconscout.com/icon/free/png-256/free-python-3628999-3030224.png?f=webp" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-mono text-xl">Python</div>
                                    <div className="text-sm opacity-50">Guido van Rossum</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Novice
                            <br/>
                            <span className="badge badge-ghost badge-sm">3yr</span>
                        </td>
                        <td>RemoveBG</td>
                    </tr>
                    {/* row 4 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Lua-Logo.svg/600px-Lua-Logo.svg.png?20150107024942" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-mono text-xl">Lua</div>
                                    <div className="text-sm opacity-50">Tecgraf</div>
                                </div>
                            </div>
                        </td>
                        <td>
                           Novice
                            <br/>
                            <span className="badge badge-ghost badge-sm">4yr</span>
                        </td>
                        <td>Weston's Pizzeria</td>
                    </tr>
                    {/* row 5 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Dart-logo-icon.svg/2048px-Dart-logo-icon.svg.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-mono text-xl">Dart</div>
                                    <div className="text-sm opacity-50">Google</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Beginner
                            <br/>
                            <span className="badge badge-ghost badge-sm">1yr</span>
                        </td>
                        <td>Barbe-queue</td>
                    </tr>
                    {/* row 6 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://banner2.cleanpng.com/20180810/biz/kisspng-javascript-scalable-vector-graphics-logo-encapsula-javascript-le-ekran-grnts-almak-alpere-5b6dbeb48e4583.2854840415339189005828.jpg" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-mono text-xl">JavaScript</div>
                                    <div className="text-sm opacity-50">Brendan Eich</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Beginner
                            <br/>
                            <span className="badge badge-ghost badge-sm">2yr</span>
                        </td>
                        <td>Burning Links</td>
                    </tr>
                    {/* row 7 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://cdn-icons-png.flaticon.com/512/732/732212.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-mono text-xl">HTML</div>
                                    <div className="text-sm opacity-50">Tim Berners-Lee</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Intermediate
                            <br/>
                            <span className="badge badge-ghost badge-sm">8yr</span>
                        </td>
                        <td>My Website</td>
                    </tr>
                    {/* row 8 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://cdn.iconscout.com/icon/free/png-256/free-css3-9-1175237.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-mono text-xl">CSS</div>
                                    <div className="text-sm opacity-50">Hakon Wium Lie</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Intermediate
                            <br/>
                            <span className="badge badge-ghost badge-sm">8yr</span>
                        </td>
                        <td>My Website</td>
                    </tr>
                    </tbody>
                    {/* foot */}

                </table>
            </div>

            <div className="font-bold text-4xl text-center mt-16">Projects</div>

            <div className="overflow-x-auto mt-12 mb-12  ml-6 mr-6 shadow-lg p-8 bg-primary-content bg-opacity-20 rounded-lg mt-12">
                <table className="table">
                    {/* head */}
                    <thead>
                    <tr>
                        <th>Project</th>
                        <th>Team</th>
                        <th>Tech Stack</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* row 1 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://pngimg.com/d/gamepad_PNG28.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">600 Doors</div>
                                    <div className="text-sm opacity-50">Desktop/Mobile Game</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes, Griffin Lewis
                            <br/>
                            <span className="badge badge-ghost badge-sm">Team</span>

                        </td>
                        <td>
                            <span className="badge badge-ghost badge">Unity</span>
                            <span className="badge badge-ghost badge">C#</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 2 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://pngimg.com/d/gamepad_PNG28.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">GraviCube</div>
                                    <div className="text-sm opacity-50">Mobile Game</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">Unity</span>
                            <span className="badge badge-ghost badge">C#</span>
                            <span className="badge badge-ghost badge">IronForge</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 3 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://pngimg.com/d/gamepad_PNG28.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">Rituals</div>
                                    <div className="text-sm opacity-50">Desktop Game</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">Unity</span>
                            <span className="badge badge-ghost badge">C#</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 4 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://pngimg.com/d/gamepad_PNG28.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">Sneaker World</div>
                                    <div className="text-sm opacity-50">Mobile Game</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes, Tion Bell
                            <br/>
                            <span className="badge badge-ghost badge-sm">Team</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">Unity</span>
                            <span className="badge badge-ghost badge">C#</span>
                            <span className="badge badge-ghost badge">Firebase</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 5 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://pngimg.com/d/gamepad_PNG28.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">Weston's Pizzeria</div>
                                    <div className="text-sm opacity-50">Desktop/Mobile Game</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">Roblox</span>
                            <span className="badge badge-ghost badge">Lua</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 6 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://pngimg.com/d/gamepad_PNG28.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">Top Down Shooter</div>
                                    <div className="text-sm opacity-50">Desktop/Mobile Game</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">Unity</span>
                            <span className="badge badge-ghost badge">C#</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 7 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://cdn-icons-png.flaticon.com/512/3039/3039396.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">File Convert</div>
                                    <div className="text-sm opacity-50">Desktop Application</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">C#</span>
                            <span className="badge badge-ghost badge">Avalonia</span>
                            <span className="badge badge-ghost badge">Ffmpeg</span>
                            <span className="badge badge-ghost badge">ImageMagick</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 9 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://cdn-icons-png.flaticon.com/512/3039/3039396.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">Word of the Day</div>
                                    <div className="text-sm opacity-50">Desktop Application</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">C#</span>
                            <span className="badge badge-ghost badge">Avalonia</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 10 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://cdn-icons-png.flaticon.com/512/3039/3039396.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">YTtoMP4</div>
                                    <div className="text-sm opacity-50">Desktop Application</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">C#</span>
                            <span className="badge badge-ghost badge">YoutubeMedia</span>
                            <span className="badge badge-ghost badge">Avalonia</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 11 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://cdn-icons-png.flaticon.com/512/3039/3039396.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">RemoveBG</div>
                                    <div className="text-sm opacity-50">Desktop Application</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">Python</span>
                            <span className="badge badge-ghost badge">TKinter</span>
                            <span className="badge badge-ghost badge">Pillow</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 8 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://www.freeiconspng.com/thumbs/website-icon/website-icon-11.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">Burning Links</div>
                                    <div className="text-sm opacity-50">Web Extension</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">JavaScript</span>
                            <span className="badge badge-ghost badge">HTML</span>
                            <span className="badge badge-ghost badge">CSS</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>
                    {/* row 12 */}
                    <tr>
                        <td>
                            <div className="flex items-center gap-3">
                                <div className="avatar">
                                    <div className="mask mask-squircle w-12 h-12">
                                        <img src="https://www.freeiconspng.com/thumbs/website-icon/website-icon-11.png" alt="Avatar Tailwind CSS Component" />
                                    </div>
                                </div>
                                <div>
                                    <div className="font-bold">Personal Website</div>
                                    <div className="text-sm opacity-50">Website</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            Weston Forbes
                            <br/>
                            <span className="badge badge-ghost badge-sm">Solo</span>
                        </td>
                        <td>
                            <span className="badge badge-ghost badge">JS</span>
                            <span className="badge badge-ghost badge">HTML/CSS</span>
                            <span className="badge badge-ghost badge">React</span>
                            <span className="badge badge-ghost badge">TailwindCSS</span>
                            <span className="badge badge-ghost badge">DaisyUI</span>
                        </td>
                        <th>
                            <button className="btn btn-neutral btn-xs">VIEW</button>
                        </th>
                    </tr>

                    </tbody>
                    {/* foot */}

                </table>
            </div>












        </div>

    );
}