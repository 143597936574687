import React from 'react';
import '../output.css';

// Initialization for ES Users
import {Dropdown, Collapse, initMDB} from "mdb-ui-kit";

initMDB({Dropdown, Collapse});

function Navbar() {

    return (

        <div className="navbar bg-primary-content" data-theme={"luxury"}>
            <div className="navbar-start">
                <div className="dropdown">
                    <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M4 6h16M4 12h8m-8 6h16"/>
                        </svg>
                    </div>
                    <ul tabIndex={0}
                        className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/portfolio">Portfolio</a></li>
                        <li><a href="/blog">Blog</a></li>
                    </ul>
                </div>

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="btn btn-ghost text-xl font-mono " href="/">WESTON FORBES</a>
            </div>
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1">
                    <li><a href="/">
                        Home</a></li>
                    <li><a href="/about">
                        About</a></li>
                    <li><a href="/portfolio">
                        Portfolio</a></li>
                    <li><a href="/blog">
                        Blog


                    </a></li>
                </ul>
            </div>
            <div className="navbar-end">

            </div>
        </div>

    );
}

export default Navbar;