
import React from 'react';
import Gif from '../Images/Silk.mp4';

export default function Home() {



    return (
        <div className="hero min-h-screen relative transition-opacity duration-300 opacity-100" data-theme={"luxury"}>
            <div className="video-container absolute inset-0 z-0 overflow-hidden">
                <video autoPlay muted loop className="object-cover absolute inset-0 w-full h-full">
                    <source src={Gif} type="video/mp4" />
                </video>
            </div>
            <div className="relative z-10">
                <div className="hero-content text-center">
                    <div className="max-w-md mx-auto h-full flex flex-col justify-center items-center">
                        <div className="max-h-md w-full bg-primary-content rounded-lg p-8">
                            <p className="py-3 font-body">I'm Weston, a 19-year-old Game Developer, and aspiring Web & Software Developer from the vibrant Greater Toronto Area.</p>
                            <p className="py-3">Embarking on my journey into the world of professional development, I'm gearing up for college to dive deeper into the realms of software development.</p>
                            <a href="/portfolio"><button className="btn btn-primary mt-4 w-48 text-xl">View</button></a>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <iframe frameBorder="0" title="game"
                                src="https://itch.io/embed/2737400?bg_color=000000&amp;fg_color=ffffff&amp;border_color=414141"
                                width="552" height="167"><a href="https://westonforbes.itch.io/600-doors">600 Doors by
                            weston forbes</a></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}