
import React from "react";

export default function Blog() {
    return (

                <div className="shadow-lg p-8 bg-black bg-opacity-25 rounded-lg  min-h-screen " data-theme={"luxury"}>
                    <div id="bh-posts"></div>
                </div>

    );
}