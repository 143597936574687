
import React from "react";
import SelfImg from '../Images/SelfImage.png';
import Gif from "../Images/Silk.mp4";

export default function About() {
    return (
        <div className="hero min-h-screen bg-base-100 relative transition-opacity duration-300 opacity-100 " data-theme={"luxury"}>
            <div className="video-container absolute inset-0 z-0 overflow-hidden">
                <video autoPlay muted loop className="object-cover absolute inset-0 w-full h-full">
                    <source src={Gif} type="video/mp4" />
                </video>
            </div>
            <div className="hero-content text-center">
                <div className="mb-12 mt-12">

                    <div className="flex justify-center items-center mt-12 mb-8">
                        <img src={SelfImg} className="w-64 rounded-xl" alt=""></img>
                    </div>

                    <h1 className="text-5xl font-bold ">About Me</h1>
                    <h2 className="text-1xl font-mono mt-2">A short autobiography of my life & my history with computers & programming</h2>


                    <div className="shadow-lg p-8 bg-primary-content bg-opacity-20 rounded-lg mt-12">
                    <p className="py-6 font-mono">I gained a near immediate fascination with computers the moment I began using them, around the age of 10 years old.

                        The sheer mystery & complexity of all the complex technologies & moving pieces in a computer completely overcame me.

                        In a sense, that mystery & complexity was the main alluring factor, and was what ultimately strengthened my appreciation for the underlying logic.</p>

                    <p className="py-6 font-mono">My initial venture into the world of what I could perceive as programming at the time, was with .BAT files, and making simple quiz/logic based games within.

                        I wouldn't necessarily say that working with BATCH files made me gain skills that have proved useful at any further point, but it was at the least a good introduction

                        into the concept of order of execution, and how a file reads & progresses as it executes.</p>

                    <p className="py-6 font-mono">Around this time, I was of course heavily interested in the world of video games, and not only playing them, but the way they're made as well.

                        I desperately wanted to make a game, one that was mine, one I could have full control over, like my own personal science experiment.

                        I had trial runs with software like RPG Maker, and GameMaker, but I finally hit a sweet spot once I found Unity 4.

                        Unity was a lot more code-centric, as opposed to RPG Maker, or GameMaker. Which I saw as a downside at the time, only later realizing how advantageous it was.

                        I delved in and began to learn what Unity would call 'JavaScript', which was in fact actually 'UnityScript'.</p>

                    <p className="py-6 font-mono">Over time, and through countless tutorial videos, I slowly began to realize that UnityScript wasn't actually the preferred language to use within Unity.

                        C# was the main language people would use within it, and I hated the idea that I would have to now re-learn syntax, and how to do things.

                        Well, I did end up learning C#, and it was entirely well worth it. UnityScript did end up getting deprecated by Unity anyway, so if I hadn't transitioned, it would've been much more troublesome.</p>

                    </div>
                    </div>
            </div>
        </div>
    );
}